<template>
  <div class="box">
    <div style="display: flex; justify-content: space-between">
      <div>
        {{ $route.query.projectName }} 选择日期：
        <el-date-picker
          style="width: 150px"
          v-model="dateValue"
          type="date"
          value-format="yyyy-MM-dd"
          size="small"
          @change="getUseRate()"
          :picker-options="pickerOption"
        />
      </div>
      <div
        style="
          cursor: pointer;
          color: rgb(2, 185, 128);
          font-size: 14px;
          line-height: 28px;
        "
        @click="$router.go(-1)"
      >
        <i class="el-icon-arrow-left"></i>返回
      </div>
    </div>

    <div style="width: 100%; height: 95%; margin-top: 20px">
      <div style="width: 100%; height: 100%" id="box"></div>
    </div>
  </div>
</template>

<script>
let that;
import * as echarts from "echarts";
export default {
  data() {
    return {
      dateValue: "",
      zoom: 1,
      pickerOption: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  created() {
    this.dateValue = this.dayjs().format("YYYY-MM-DD");
  },
  mounted() {
    that = this;
    this.zoom = 1 / document.body.style.zoom;
    that.getUseRate();
  },
  methods: {
    setHistory(date, data) {
      let option = {
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            let time = that.dayjs(params[0].value[0]).format("HH:mm:ss");
            let str = "";
            params.forEach((item) => {
              str +=
                "</br>" +
                item.marker +
                item.seriesName +
                '<span style="display:inline-block;width:30px;margin:5px 0"></span>' +
                item.value;
            });
            return '<div style="font-weight:700">' + time + str + "</div>";
          },
        },
        legend: {},
        dataZoom: [
          {
            type: "inside",
            xAxisIndex: [0],
            start: 0,
            end: 100,
          },
        ],
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: date,
          axisLabel: {
            formatter: function (value) {
              return that.dayjs(value).format("HH:mm:ss");
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
        },
        series: data,
      };
      var chartDom = document.getElementById("box");
      let myChart = echarts.init(chartDom);
      myChart.clear();
      myChart.setOption(option);
    },
    getUseRate() {
      const loading = this.$loading({
        lock: true,
        text: "加载中，请稍后....",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let from = {
        date: this.dateValue,
        projectId: that.$route.query.projectId,
      };
      this.$api.largeDataScreen.HumHistory(from).then((res) => {
        loading.close();
        if (res.code == 200) {
          let data = [];
          res.data.data.forEach((item) => {
            data.push({
              name: item.name,
              type: "line",
              data: item.streams,
              markPoint: {
                data: [
                  { type: "max", name: "Max" },
                  { type: "min", name: "Min" },
                ],
              },
              markLine: {
                data: [{ type: "average", name: "Avg" }],
              },
            });
          });
          this.setHistory(res.data.date, data);
        }
      });
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  text-align: left;
  box-sizing: border-box;
}
.box {
  padding: 10px;
  width: 100%;
  height: 100%;
}
/* @import url(); 引入css类 */
</style>
